@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css);
.required::after {
    content: " *";
    color: red;
}

body {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}

#mainFooter {
    margin-top: 4em;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

#container {
    min-height: 82vh;
}

#pageDropDown {
    margin-right: 1vw;
}

.openlayers-map {
    float: left;
}

#addGeometricElementContainer {
    float: left;
    left: 0;
    width: 30vw;
    min-height: 82vh;
    display: none;
    background-color: white;
    border-left: 6px solid black;
    max-height: 82vh;
    overflow: auto;
}

.carousel .thumb img {
    object-fit: scale-down;
    max-height: 5vh;
    max-width: auto;
}

.carousel .slide img {
    object-fit: scale-down;
    min-height: 70vh;
    max-height: 70vh;
    max-width: auto;
}

.head404 {
    font-size: 200px;
    color: #a9444a;
    font-weight: bolder;
}

.headline {
    font-size: 90px;
    font-weight: bold;
}

.help-tooltip.tooltip.show {
    opacity: 1!important;
}

.help-tooltip.tooltip-inner {
    font-size: 14px!important;
    max-width: 25vw!important;
    text-align: left!important;
    background-color: snow!important;
    color: black!important;
    border: 3px solid saddlebrown!important;
}

.help-tooltip.tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before, .help-tooltip.tooltip.bs-tooltip-left .arrow::before {
    content: "";
    border-width: 5px 0px 5px 5px!important;
    border-left-color: saddlebrown!important;
}

.help-tooltip.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before, .help-tooltip.tooltip.bs-tooltip-right .arrow::before {
    content: "";
    border-width: 5px 5px 5px 0px!important;
    border-right-color: saddlebrown!important;
}

.help-tooltip .tooltip-inner p {
    padding-top: 0px!important;
    padding-bottom: 5px!important;
    margin: 0!important;
}

.help-tooltip .tooltip-inner>*:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.help-tooltip .tooltip-inner ul {
    margin-bottom: 5px;
    padding-left: 15px;
}

.help-tooltip .tooltip-inner h1 {
    font-size: 15px;
    margin: 0;
}

td .img-thumbnail {
    object-fit: scale-down;
    max-height: 10vh;
    max-width: 30vw;
}

.react-bootstrap-table table {
    table-layout: auto!important;
}

.layer-switcher.shown>div {
    min-width: 18vw;
    max-width: 20vw;
}

.layer-switcher.shown>div {
    max-height: 65vh;
}

.layer-switcher li.layer {
    display: table!important;
}

.layer-switcher li.layer label, .layer-switcher li.layer input {
    display: table-cell!important;
    vertical-align: middle!important;
}

.layer-switcher input {
    margin: 4px!important;
}

.layer-switcher .layer-opacity {
    margin: auto!important;
    min-width: 2.5rem;
}

th .order-4:before {
    content: "\25B2"!important;
    font-weight: bolder!important;
}

th .order-4:after {
    content: "\25BC"!important;
    font-weight: bolder!important;
}

th .caret-4-asc:before {
    content: "\25B2"!important;
    font-weight: bolder!important;
}

th .caret-4-asc:after {
    content: ""!important;
    font-weight: bolder!important;
}

th .caret-4-desc:before {
    content: ""!important;
    font-weight: bolder!important;
}

th .caret-4-desc:after {
    content: "\25BC"!important;
    font-weight: bolder!important;
}

.react-bootstrap-table {
    margin-top: 15px!important;
}

.alert+#actionButons {
    margin-top: 15px;
}

#actionButtons+.filtersPanel {
    margin-top: 15px;
}

.filtersPanel {
    background-color: whitesmoke;
    padding: 10px;
    clear: both;
}

.filterPanelTitle {
    font-weight: bold;
    padding-right: 10px;
}

.filterPanelItem {
    align-items: center;
    background: rgba(126, 170, 170, 0.2);
    padding: 5px;
    margin: 3px;
    display: inline-flex;
}

.formattedUrl {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;    
}
.loadingModal {
    height: 5rem!important;
    width: 5rem!important;
}

.loadingSpinner {
    width: 3rem!important;
    height: 3rem!important;
}
.ol-zoom-extent {
    top: 0.5em;
    left: 3em;
}

.ol-zoom-extent-control {
    top: 4.5em;
    left: 0.5em;
}

.ol-zoomslider {
    top: 7em;
}

.ol-center-entity-population {
    top: 0.5em;
    left: 5.5em;
}

.ol-popup-control {
    top: 3em;
    left: 5.5em;
}

.ol-measure-area-control {
    top: 3em;
    left: 3em;
}

.ol-measure-length-control {
    top: 5.5em;
    left: 3em;
}

.ol-add-geometric-element-control {
    top: 5.5em;
    left: 5.5em;
}

#addGeometricElementSelector {
    top: 2.5em !important;
}

.ol-control-active {
    background-color: turquoise !important;
}

.ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
}

.ol-tooltip-measure {
    opacity: 1;
    background-color: dodgerblue;
    color: black;
    border: 1px solid white;
}

.ol-tooltip-measure:before {
    border-top: 6px solid dodgerblue;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}

.ol-show-legend-control {
    top: 8em;
    left: 3em;
}

.ol-other-layers-popup-control {
    top: 10.5em;
    left: 3em;
}

.ol-scale-bar {
    right: 1em;
    left: auto;
}

.ol-export-pdf-control {
    top: calc( 7.5em + 200px);
    left: 0.5em;
}

#progressiveCenterDiv, #searchCenterDiv, #popupControllerSelectorDiv {
    max-width: 0;
    transition: max-width .8s ease-in;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    line-height: 30px;
    height: 30px;
}

#progressiveCenterDiv.show, #searchCenterDiv.show, #popupControllerSelectorDiv.show {
    max-width: 85vw;
}

#searchCenterDiv input, #progressiveCenterDiv select, #searchCenterDiv button, #progressiveCenterDiv button, #popupControllerSelectorDivContent {
    display: inline-block;
    margin-left: 0.5em;
}

#progressiveCenterDiv select {
    max-width: 15vw;
}

#popupControllerSelectorDivContent {
    width: 25vw;
}

#popupcontrollerdiv {
    display: inline-block;
    vertical-align: middle;
}

.autocomplete {
    max-height: 50vh!important;
}

.autocomplete .group {
    font-weight: bolder!important;
}

#progressiveCenterDiv select {
    max-height: auto!important;
}

#centerSearchInput {
    height: auto!important;
    line-height: auto!important;
    margin: 0px;
    padding: 0px;
    min-width: 13vw;
}

.inputError {
    background-color: rgba(255, 17, 0, 0.589);
}

.legend {
    z-index: 1;
    position: fixed;
    min-width: 20vw;
    max-width: 25vw;
    width: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #dfdfdf;
    left: 3em;
    top: 14.5em;
    border: 0.5em solid #dfdfdf;
}

#legendAccordion {
    overflow-y: auto;
    min-height: 5vh;
    max-height: 50vh;
}

#legendAccordion>div.card>div.card-header {
    cursor: pointer;
    font-weight: bold;
}

#legendAccordion>div.card>div.card-header::before {
    font-family: 'FontAwesome';
    content: "\f078";
    /* fa-chevron-down */
    margin-right: 5px;
}

#legendAccordion>div.card>div.card-header[aria-expanded="true"]::before {
    content: "\f077";
    /* fa-chevron-up */
}

#closeLegend {
    text-decoration: none;
    position: absolute;
    top: 0;
    right: 2px;
    z-index: 3;
}

.ol-mouse-position {
    right: 0;
    left: 0;
    bottom: 0;
    top: auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: #eeeeee;
}

.ol-bookmark {
    top: calc( 10em + 200px)!important;
    left: 0.5em!important;
    overflow: auto;
}
.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-height: 10vh;
  max-height: 40vh;
  min-width: 20vw;
  max-width: 25vw;
  z-index: 1;
}

.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  size: 1em;
  color: red;
  top: 0;
  right: 2px;
  z-index: 3;
}

.ol-popup-content {
  max-height: 35vh;
  overflow: auto;
  z-index: 2;
  scroll-margin: 1em;
}

.ol-popup-content span.btn-link {
  cursor: pointer;
  margin-right: 0.5em;
}

.ol-popup-content div.card>div.card-header[data-toggle="collapse"] {
  cursor: pointer;
}

.ol-popup-content div.card>div.card-header[data-toggle="collapse"]::before {
  font-family: 'FontAwesome';
  content: "\f078";
  /* fa-chevron-down */
  margin-right: 5px;
}

.ol-popup-content div.card>div.card-header[aria-expanded="true"]::before {
  content: "\f077";
  /* fa-chevron-up */
}
