.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-height: 10vh;
  max-height: 40vh;
  min-width: 20vw;
  max-width: 25vw;
  z-index: 1;
}

.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  size: 1em;
  color: red;
  top: 0;
  right: 2px;
  z-index: 3;
}

.ol-popup-content {
  max-height: 35vh;
  overflow: auto;
  z-index: 2;
  scroll-margin: 1em;
}

.ol-popup-content span.btn-link {
  cursor: pointer;
  margin-right: 0.5em;
}

.ol-popup-content div.card>div.card-header[data-toggle="collapse"] {
  cursor: pointer;
}

.ol-popup-content div.card>div.card-header[data-toggle="collapse"]::before {
  font-family: 'FontAwesome';
  content: "\f078";
  /* fa-chevron-down */
  margin-right: 5px;
}

.ol-popup-content div.card>div.card-header[aria-expanded="true"]::before {
  content: "\f077";
  /* fa-chevron-up */
}